import React from "react"
import { Link } from "gatsby"

import MenuLayout from "../components/menulayout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <MenuLayout>
    <SEO title="404: Not found" />
    <section className="row">
      <article className="col-xs-12">
        <div className="bg-pattern border mega" style={{ height: 400 }}>
          <h1>404</h1>
          <h2>Diese Seite existiert nicht. </h2>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 bg-pattern button">
          <button className="border animated">
            <Link title="Zurück zu Inhalten." to="/">
              Zur Startseite
            </Link>
          </button>
        </div>
      </article>
    </section>
  </MenuLayout>
)

export default NotFoundPage
